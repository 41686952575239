import React, { useEffect, useState } from 'react';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import nexo from '../../nexoClient';
import { Layout, Page } from '@nimbus-ds/patterns';
import {
  Box,
  Button,
  Select,
  Table,
  Title,
  Text,
  Card,
} from '@nimbus-ds/components';
import axiosInstance from '../../axios';

const MetricsPage: React.FC = () => {
  const [sellers, setSellers] = useState<Seller[]>([]);
  const [currentSeller, setCurrentSeller] = useState<Seller>();
  const [salesCount, setSalesCount] = useState<string>();
  const [totalSalesCount, setTotalSalesCount] = useState<string>();
  const [totalAmount, setTotalAmount] = useState<string | number>();
  const [totalOrdersSold, setTotalOrdersSold] = useState<string>();
  const [productsSync, setProductsSync] = useState<string>();

  interface Seller {
    store_id: number;
    nombre_tienda: string;
    url: string;
  }

  const headers = [
    'Id',
    'Nombre Tienda',
    'Origen producto',
    'Tipo oferta',
    'Registro marca',
    'Url',
    'Nombre',
    'Teléfono',
    'Email',
    'Acciones',
  ];

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: 'Volver al inicio' });
  }, []);

  useEffect(() => {
    getSellersList();
  }, []);

  const getSellersList = async () => {
    console.log('get sellers list');

    await axiosInstance
      .get('/approved_sellers_list')
      .then((response) => {
        setSellers(response?.data?.sellers);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChangeSellerSelect = async (e: any) => {
    console.log('handle change seller list');
    console.log(e.target.value);
    console.log(e.target.label);
    for await (const seller of sellers) {
      if (seller.store_id == e.target.value) {
        console.log('seller found');
        const currentSeller: Seller = {
          store_id: seller.store_id,
          nombre_tienda: seller.nombre_tienda,
          url: seller.url,
        };
        setCurrentSeller(currentSeller);
        getMetrics(e.target.value);
        break;
      }
    }
  };

  const getMetrics = async (store_id: number) => {
    console.log('get metrics');

    await axiosInstance
      .get('/metrics', {
        params: {
          store_id: store_id,
        },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data?.metrics) {
          if (
            response.data.metrics.product_metrics &&
            response.data.metrics.product_metrics.length
          ) {
            setSalesCount(response.data.metrics.product_metrics[0].sales_count);
            setTotalSalesCount(
              response.data.metrics.product_metrics[0].total_sales_count,
            );
            setTotalAmount(
              response.data.metrics.product_metrics[0].total_amount,
            );
          }
          setTotalOrdersSold(
            response.data.metrics.order_metrics?.total_orders_sold,
          );
          setProductsSync(response.data.metrics.products_sync);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Page maxWidth="1500px" marginTop="18">
      <Page.Header alignItems="center" title="Metricas" />
      <Page.Body>
        <Layout columns="1">
          <Layout.Section>
            <Card>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="4"
                marginBottom="6"
              >
                <Text>Seleccionar Seller</Text>
                <Select
                  appearance="neutral"
                  id="seller-list-select"
                  name="Name"
                  onChange={(event) => {
                    handleChangeSellerSelect(event);
                  }}
                >
                  <Select.Option
                    disabled
                    label="Seleccionar Seller"
                    selected={currentSeller == null}
                    value="Seleccionar Seller"
                  />
                  {sellers ? (
                    sellers.map((seller: Seller) => (
                      <Select.Option
                        key={seller.store_id}
                        value={String(seller.store_id)}
                        label={seller.nombre_tienda}
                      ></Select.Option>
                    ))
                  ) : (
                    <Select.Skeleton />
                  )}
                </Select>
              </Box>
            </Card>
            {currentSeller ? (
              <Card>
                <Box display="flex" flexDirection="column" gap="4" padding="4">
                  <Text>
                    <strong>Ventas producto:</strong> {salesCount}
                  </Text>
                  <Text>
                    <strong>Ventas unitarias producto:</strong>{' '}
                    {totalSalesCount}
                  </Text>
                  <Text>
                    <strong>Monto total ventas:</strong> {totalAmount}
                  </Text>
                  <Text>
                    <strong>Nro total ventas:</strong> {totalOrdersSold}
                  </Text>
                  <Text>
                    <strong>Productos sincronizados:</strong> {productsSync}
                  </Text>
                </Box>
              </Card>
            ) : (
              <div></div>
            )}
          </Layout.Section>
        </Layout>
      </Page.Body>
    </Page>
  );
};

export default MetricsPage;
